@import "../../partials/variables";

.btn {
  width: 50px;
  height: 50px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  background-color: $black-color;
  margin: 30px;
  border-radius: 2%;
  padding: 1rem;

  &::before {
    content: "";
    top: 22px;
    left: 17px;
    width: 15px;
    height: 15px;
    position: absolute;
    border-right: 2px double $body-color;
    border-top: 2px double $body-color;
    transform: rotate(-45deg);
  }

  &:hover {
    background-color: $text-color;
  }
}

.visibility {
  &--visible {
    visibility: visible;
  }
  &--hidden {
    visibility: hidden;
  }
}
