.error-container {
  margin: 200px 20px;
  text-align: center;

  h2 {
    margin-top: 20px;
    color: #333;
    font-size: xx-large;
  }
}
