.contact-container {
  border: 2px solid rgba(211, 209, 209, 0.2);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 30px 40px;
  backdrop-filter: blur(30px);
  background: transparent;
  width: 100%;

  // @media (min-width: 768px) {
  //   width: 100%;
  // }

  h2 {
    @media (max-width: 968px) {
      display: flex;
      justify-content: center;
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    width: 40rem;

    p{
      color: rgb(246, 6, 6);
      padding-left: 10px;
    }

    .contact-form-line {
      display: flex;
      flex-direction: row;
      width: 100%;

      input {
        width: 20rem;

        // @media (max-width: 768px) {
        //   padding: 10px;
        //   width: 100%;
        // }
      }
    }

    input {
      padding: 20px;
      margin-top: 20px;
      width: 100%;

      // @media (min-width: 768px) {
      //   padding: 10px;
      //   width: 100%;
      // }
    }

    textarea {
      height: 200px;
      padding: 20px;
      margin-top: 20px;
      width: 100%;
    }

    button {
      margin-top: 20px;
      width: 50%;
    }

    @media (max-width: 768px) {
      width: 15rem;

      .contact-form-line {
        display: flex;
        flex-direction: column;

        input {
          width: 100%;
        }
      }

      button {
        width: 100%;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 5px 10px;
  }
}
