@import "../../partials/variables";

.navbar-container {
  width: 100%;
  transition: all 0.5s ease-in-out;
  position: fixed;
  top: 0;
  z-index: 1;
}

.height {
  &--max {
    height: 100vh;
  }
  &--min {
    height: 5rem;
  }
}

.bg-color {
  &--transparent {
    background-color: transparent;
  }
  &--color {
    background-color: #333;
  }
}

.navbar-inner {
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
}

.left {
  flex: 30%;
  padding-left: 5%;
  text-decoration: none;
}

.right {
  flex: 70%;
  padding-right: 5%;
}

.navbar-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    display: flex;
    width: 70px;
    height: 50px;
    background: transparent;
    border: none;
    font-size: 35px;
    cursor: pointer;
    align-items: center;

    &:hover {
      background: none;
    }

    @media (min-width: 1024px) {
      display: none;
    }

    @media (max-width: 1024px) {
      svg > path {
        stroke: #ffffff;

        &:hover {
          stroke: #9ceeff;
        }
      }
    }
  }

  .navbar-icon{   
    padding-top: 10px;
  }
}

.log-in-icon {
  position: relative;
  color: #f2f2f2;
  font-size: x-large;
  display: flex;
  padding-left: 20px;
  cursor: pointer;  

  &:hover,
  &:focus {
    color: $primary-color;
  }

  &:hover .tooltip-text {
    top: 130%;
    visibility: visible;
    opacity: 1;
  }

  .tooltip-text {
    position: absolute;
    left: 50%;
    top: 150%;
    transform: translateX(-50%);
    background-color: $primary-color;
    color: #fff;
    padding: 5px 4px;
    border-radius: 7px;
    font-size: medium;
    margin-left: 10px;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease;

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent $primary-color transparent;
    }
  }
}

.navlink {
  color: #f2f2f2;
  font-size: large;
  text-decoration: none;
  margin: 10px;
  padding: 10px;

  &.active {
    color: $primary-color;
  }

  &.active::after {
    background: $primary-color;
    width: 100%;
  }

  &--contact-link {
    text-transform: uppercase;

    &::after {
      content: "";
      display: block;
      height: 0.1rem;
      width: 0;
      background: $primary-color;
      width: 100%;
    }
  }

  &:hover,
  &:focus {
    color: $primary-color;
    cursor: pointer;
  }

  &:hover:after {
    width: 100%;
    background: $primary-color;
    transition: width 0.7s ease, background-color 0.5s ease;
  }

  &::after {
    content: "";
    display: block;
    height: 0.1rem;
    width: 0;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}

// .navbar-contact-link {
//   text-transform: uppercase;
//   position: relative;

//   &::before {
//     position: absolute;
//     content: "";
//     right: 0;
//     bottom: 0;
//     left: 0;
//     width: auto;
//     height: 1px;
//     background-color: #f2f2f2;
//     transition: all 0.35s ease;
//   }
// }

.link {
  color: #f2f2f2;
  font-size: x-large;
  text-decoration: none;
  margin: 10px;
}

.navbar-extended {
  display: flex;
  flex-direction: column;
  align-items: center;
 

  @media (min-width: 1024px) {
    display: none;
  }

  a {
    color: #f2f2f2;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;

    &:hover,
    &:focus {
      color: $primary-color;
      cursor: pointer;
    }
  }
}

// export const StyledNavMenu = styled.ul`
//   overflow: hidden;
//   width: 100%;
//   text-align: center;
//   height: auto;

//   li {
//     display: inline-block;
//     padding: 1% 0.7%;
//     text-align: center;
//     font-size: 1.2em;
//     cursor: pointer;

//     &:after {
//       content: "";
//       display: block;
//       height: 0.1rem;
//       width: 0;
//       background: transparent;
//       transition: width 0.7s ease, background-color 0.5s ease;
//     }

//     &:hover:after {
//       width: 100%;
//       background: var(--primary-color);
//     }

//     & .active {
//       color: var(--primary-color);
//       border-bottom: 2px solid var(--primary-color);
//     }
//   }

 

//   @media (max-width: 960px) {
//     display: flex;
//     flex-direction: column;
//     background-color: #333;
//     position: absolute;
//     transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
//     top: 4rem;
//     right: 0;
//     height: 100vh;
//     width: 70%;
//     border-top: 1px solid #fff;
//     padding-top: 3.5rem;
//     transition: transform 0.3s ease-in-out;
//     opacity: 1;

//     .link {
//       display: block;
//     }
//   }
// `;

// export const StyledBurgerMenu = styled.div`
//   display: none;

//   @media (max-width: 960px) {
//     display: block;
//     width: 2rem;
//     height: 2rem;
//     position: fixed;
//     top: 1rem;
//     right: 0;
//     justify-content: space-around;

//     svg > path {
//       stroke: #ffffff;

//       &:hover {
//         stroke: #9ceeff;
//       }
//     }
//   }
// `;
