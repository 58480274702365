.footer {
  overflow: hidden;
  background-color: #333;
  bottom: 50px;
  width: 100%;
  padding: 0.6rem;
  z-index: 10;

  hr {
    background-color: none;
    border-color: gray;
    height: 1.5px;
    margin: 20px 80px;
  }

  h5 {
    color: #fff;
    font-size: 1.2rem;
  }
}

.footer-top {
  display: flex;
  justify-content: space-between;
  margin: 20px 80px;
  font-size: 0.85rem;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin: 20px auto;
  }

  .logo-container {
    display: flex;
    align-items: center;
    text-decoration: none;
    margin: 10px;

    img {
      height: 2rem;
      margin: 0;
      margin-right: 0.5rem;
    }

    span {
      color: #fff;
    }
  }
  
  .contact-link {
    display: flex;
    position: relative;
    text-transform: uppercase;
    align-items: center;
    text-decoration: none;
    color: #6a737b;
    cursor: pointer;

    &::before {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #6a737b;
      transition: all 0.35s ease;
    }

    &:hover::before {
      bottom: -0.2rem;
      background-color: #8b9399;
      transition: all 0.35s ease;
    }
  }
}

.footer-middle {
  display: flex;
  justify-content: space-around;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-middle-layout {
    display: flex;
    flex-direction: column;
    margin: 10px;

    @media (max-width: 600px) {
      justify-content: center;
      align-items: center;
    }

    .link {
      text-decoration: none;
      color: #fff;
      font-size: 0.85rem;
      cursor: pointer;
    }
  }
}

.footer-bottom {
  color: #ddd;
  text-decoration: none;
  font-size: 0.7rem;
  line-height: 90%;
  display: flex;
  justify-content: space-between;
  margin: 20px 80px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
  }

  span {
    font-family: "Dancing Script", cursive;
    font-size: 1.2rem;
    color: var(--violet-color);
    text-decoration: none;
  }
}
