.response-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  position: fixed;
  inset: 0;
  // background-color: #c1c2c7;
  opacity: 80;
}

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  inset: 40;
  background-color: white;
  z-index: 1000;
  border: solid 1px black;
}
