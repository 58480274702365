.logo-image {
  margin: 10px;
  max-width: 180px;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;

  &--normal {
    height: 4.5rem;
  }
  &--smaller {
    height: 3.5rem;
  }
}

//nefunguje
.border {
  &--none {
    border: none;
  }
  &--display {
    border: 1px solid #fff;
  }
}

// @media (max-width: 700px) {
//   height: 5rem;
// }
