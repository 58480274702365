@import "../../partials/variables";

.realization {
  background: #fff;
  margin: 0 4rem;

  @media (max-width: $breakpoint-mobile) {
    margin: 0 1rem;
  }
}
