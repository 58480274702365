.map {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  .map-container {
    width: 50%;
    height: 50vh;
  }
}
