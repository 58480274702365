@import "../../partials/variables";

.reference-container {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    color: #000000;
    justify-content: center;
  }

  button {
    display: block;
    margin: 2rem auto;
    transition: all 0.2s ease-in-out;
    border: none;
    border-radius: 40px;
    background: $secondary-color;
    color: #ffffff;
    padding: 1rem;
    width: 30%;
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    text-align: center;

    &:hover {
      background: $primary-color;
      cursor: pointer;
    }

    @media (max-width: $breakpoint-desktop) {
      font-size: 0.8rem;
    }

    @media (max-width: $breakpoint-tablet) {
      width: 80%;
    }
  }

  .rating-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 2rem auto;
    justify-content: center;

    .rating-show {
      width: 25rem;
      margin: 1.5rem;
      padding: 1rem;

      .message-show {
        word-break: break-word;
        word-wrap: normal;
      }

      .label-show {
        display: flex;
        justify-content: center;
        color: grey;
        margin-top: 30px;

        .rating-icon {
          margin-right: 10px;
          font-size: 30px;
          color: $primary-color;
        }
      }

      hr {
        width: 80%;
        height: 2px;
        margin: 20px auto;
        border: none;
        background: linear-gradient(
          to left,
          white 2%,
          $primary-color 50%,
          white 98%
        );
      }
    }
  }
}
