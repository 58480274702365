.pagination {
  display: flex;
  justify-content: center;

  ul {
    display: flex;
    margin-bottom: 2rem;
  }

  .pagination > li {
    list-style: none;
  }
  .pagination > li > a,
  .pagination > li > span {
    padding: 8px 20px;
    line-height: 1.5;
    border: 1px solid #ddd;

    @media (max-width: 600px) {
      padding: 6px 15px;
    }
  }

  .pagination > li.active > a {
    color: #fff;
    background-color: gray;
    border-color: #ddd;
  }

  .pagination > li > a:hover {
    background-color: gray;
    color: white;
    cursor: pointer;
  }
}
