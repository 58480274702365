@import "../../partials/variables";

.fast-demand-container {
  width: 900px;
  height: 300px;
  background: #333;
  border-radius: 10px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: $breakpoint-desktop){
    width: 90%;
    height: 200px;
  }
  
  .fast-demand-inner {
    text-align: center;
    color: #fff;

    p {
      font-size: 24px;

      @media (max-width: $breakpoint-tablet){
        font-size: 16px;
      }
    }

    span {
      font-size: 50px;
      font-weight: 600;

      @media (max-width: $breakpoint-tablet){
        font-size: 25px;
        font-weight: 600;
      }
    }

    a {
      margin-top: 40px;
    }
  }
}
