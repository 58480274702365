.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 5rem;
  background: rgba(0, 0, 0, 0.5);

  .backdrop {
    width: 100%;
    height: 100%;
  }

  .image {
    display: block;
    max-width: 40%;
    height: 80%;
    width: 80%;
    margin: 4rem auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
  }

  @media (max-width: 960px) {
    width: 100%;
    height: 100%;

    .image {
      max-width: 50%;
    }
  }

  @media (max-width: 600px) {
    .image {
      max-width: 100%;
    }
  }
}
