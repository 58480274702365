@import "../../partials/variables";

.cooperation {
  display: flex;
  flex-direction: column;
  background-image: url("../../images/Spolupracee.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
  background-position: center;
  padding: 7rem 8rem;

  @media(max-width:$breakpoint-tablet){
    padding: 4rem 1rem;
  }

  h1 {
    text-align: left;
    margin-bottom: 50px;
    font-weight: normal;

    @media(max-width:$breakpoint-tablet){
      text-align: center;
    }
  }

  .cooperation-part {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  
    .cooperation-icon {
      font-size: 50px;
    }
    
    .cooperation-info {
      margin-left: 10px;
    
      h3 {
        color: black;
        margin-bottom: 10px;
      }
    }
  }
}




