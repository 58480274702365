@import "../../partials/variables";

.contact-link-container {
  margin-top: 40px;  
  
  a {
    text-decoration: none;
    padding: 10px 40px;
    background-color: $primary-color;
    color: #fff;
    border-radius: 20px 20px 20px 0px;
    font-size: 24px;

    &:hover {
      background-color: #fff;
      color: #333;
    }
  }
}
