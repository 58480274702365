@import "../../partials/variables";

.one-realization-container {
  margin: 100px auto;

  .one-realization {
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    width: 70%;
    padding: 30px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(30px);
    border-radius: 10px;

      h2 {
        text-align: center;
        font-size: $larger-font-size;
        margin-bottom: 20px;
      }

      .one-realization-content {
        display: flex;
        flex-direction: row;

        @media (max-width: $breakpoint-tablet) {
          display: flex;
          flex-direction: column;
        }

          img {
            width:100%;
            max-width: 400px;
            max-height: 300px;
            height: auto;
            border-radius: 10px;
          }

          .one-realization-decription {
            margin-left: 20px;

              article {
                margin-bottom: 20px;
                
                @media (max-width: $breakpoint-tablet) {   
                    margin-top: 20px;            
                }

                span {
                  font-weight: $weight-600;
                }

              }
          }
      }
  }
}
