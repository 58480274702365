.home-scroll-down-button {
  width: 60px;
  height: 60px;
  top: 55%;
  left: 50%;
  position: absolute;
  border-radius: 50%;
  animation: down 1.5s infinite;
  padding: 1rem;
  cursor: pointer;

  @media (max-width: 768px){
    top: 80%;
  }

  &::before {
    content: "";
    top: 15px;
    left: 18px;
    width: 18px;
    height: 18px;
    position: absolute;
    border-left: 2px double #fff;
    border-bottom: 2px double #fff;
    transform: rotate(-45deg);
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }
    20% {
      transform: translateY(15px);
    }
    40% {
      transform: translateY(0);
    }
  }
}
