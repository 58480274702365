@import "../../partials/variables";

.one-realization-link {
  position: relative;
  text-align: start;
  margin-top: 100px;
  margin-left: 40px;

  a {
    text-decoration: none;
    border-radius: 50px;
    padding: 10px 50px;
    cursor: pointer;
    font-size: $large-font-size;
    color: #111;

    &:hover {
      text-decoration: underline;
      color: $primary-color;
    }
  }

  .icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: $large-font-size;
    z-index: 0;
    color: #111;
  }
}