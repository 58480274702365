.uploadform {
  display: flex;
  flex-direction: column;
  border: 2px solid red;
  max-width: 600px;
  padding: 20px;
  margin: 20px auto;

  h2 {
    margin: 10px;
  }

  input,
  select {
    padding: 10px;
    margin: 10px;
    width: 70%;
  }

  button {
    margin-top: 20px;
    width: 100%;

    @media (max-width: 960px) {
      font-size: 0.8rem;
    }
  }
}
