@import "../../partials/variables";

.home {
  .home-image {
    position: relative;
    img {
      width: 100%;
    }
    
    .home-header{
   
      position: absolute;
      top: 35%;
      left: 50%;
      transform: translate(-50%, -50%);


      @media (max-width: 1024px){
          top:40%;
      }




      h1 {
        color: $body-color;
        animation: fadein 6s;
  
        @media (max-width: 1024px){
          
          font-size: xx-large;
        }
  
        // @media (max-width: 768px){
        //   top: 20%;
        //   font-size: x-large;
        // }
  
        span {
          text-transform: lowercase;
        }
      }


      .home-link {
        position: absolute;
        color: #fff;
        background-color: transparent;
        border: none;
        font-size: 1rem;
        text-transform: uppercase;
        border-bottom: 1px solid #fff;
        top: 75%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-decoration: none;
        margin-top: 15px;
        animation: fadein 6s;
    
        @media (max-width: 1024px){
          top: 75%;
          font-size: 0.65rem;
        }
    
        // @media (max-width: 768px){
        //   top: 65%;
        // }
    
        // @media (max-width: 480px){
        //   top: 65%;
        // }
      }
    }



    

    @keyframes fadein {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  }

 

  // @keyframes fadein {
  //   from {
  //     opacity: 0;
  //   }
  //   to {
  //     opacity: 1;
  //   }
  // }

  // .slider-section {
  //   display: flex;
  //   flex-direction: column;

  //   // h3 {

  //   //   //   @media (max-width: 960px) {
  //   //   //     font-size: 2rem;
  //   //   //   }

  //   //   //   @media (max-width: 600px) {
  //   //   //     font-size: 1.5rem;
  //   //   //   }
  //   // }
  // }

  .header-service {
    color: #6a737b;
    text-align: center;
    background-color: #333;
    font-size: $h1-font-size;
    padding: 6rem 0;

    @media (max-width: 768px){
      font-size: xx-large;
    }
  }

  .why-us-container {
    color:#333; 
    margin: 3rem auto;

    .why-us-header {
      color:#333; 
      text-align: center;
      font-size: $h1-font-size;
      padding: 2rem 0;
    }
  
    .why-us-inner {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
  
      .why-us-one-item {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        width: 25%;

        @media (max-width: 768px) {
          width: 50%;
        }
  
        span{
          font-size: 80px;
          font-weight: 700;
        }

        h4 {
          color:#333; 
          font-size: 30px;
        }

        p{
          padding-top: 10px;
          font-size: large;
        }
      }
    }
  }

}
