@import "partials/variables";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  font-family: $body-font;
}

input,
textarea {
  font-family: $body-font;
  font-size: $normal-font-size;
  font-weight: $weight-500;
  border-radius: 40px;
  border: 2px solid $secondary-color;
  outline-color: $primary-color;
  line-height: 1.7;

  ::placeholder {
    transition: all 0.2s ease-in-out;
  }

  :focus {
    ::placeholder {
      color: transparent;
    }
  }
}

h1,
h2,
h3,
h4 {
  color: $title-color;
  font-family: $second-font;
  font-weight: $weight-700;
}

h1 {
  font-size: $h1-font-size;
  text-align: center;
  margin-top: 8rem;
  margin-bottom: 4rem;

  @media (max-width: $breakpoint-mobile) {
    font-size: $h2-font-size;
  }
}

h2 {
  font-size: $h2-font-size;
}

button {
  transition: all 0.2s ease-in-out;
  border: none;
  border-radius: 40px;
  background: $secondary-color;
  color: $body-color;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  text-align: center;
  padding: 1rem 3rem;
  cursor: pointer;

  &:hover {
    background: $primary-color;
  }
}

// body,
// html {
//   font: 18px Helvetica, Arial, sans-serif;
//   line-height: 1.7;
//   // background-repeat: no-repeat;
//   // background-position: top;
//   // background-size: 100% 75%;
//   // // // background-attachment: fixed;
//   // // // background-color: #aaadab;
//   // background-image: url(${background});
//   // background-blend-mode: overlay;
//   // height: 50%;
//   // background: url(${background});
//   // background-size: cover;
//   // background-repeat: no-repeat;
//   // background-position: 50%;
// }

// @media (max-width: 1280px) {
//   h1 {
//     font-size: 3.5rem;
//     margin: 5rem auto;
//     span {
//       font-size: 2.5rem;
//     }
//   }
// }

// @media (max-width: 960px) {
//   h1 {
//     margin: 0.5rem auto;
//   }
// }

// @media (max-width: 600px) {
//   h1 {
//     font-size: 2.5rem;
//     span {
//       font-size: 2rem;
//     }
//   }
// }

// @media (max-width: 380px) {
//   h1 {
//     font-size: 2rem;
//   }
// }
