.rating-modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .rating-background {
    position: fixed;
    inset: 0;
    background: url("../../images/rating.jpg") no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .rating-modal {
    display: flex;
    flex-direction: column;
    top: 15%;
    position: sticky;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 600px;
    color: #fff;
    border-radius: 10px;
    padding: 30px 40px;

    @media (max-width: 768px) {
      width: 85%;
    }

    .rating-form-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      h2 {
        color: #fff;
        font-size: 36px;
        text-align: center;
      }

      input,
      textarea {
        width: 100%;
        height: 100%;
        background: transparent;
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        margin: 10px 0;

        &::placeholder {
          color: #fff;
        }
      }

      button {
        width: 100%;
      }

      p{
        color: rgb(246, 6, 6);
        padding-left: 10px;
      }

      @media (max-width: 300px) {
        width: 100%;
        padding: 0.25rem 0.25rem;

        input,
        textarea {
          padding: 10px;
          margin: 5px;
        }

        button {
          width: 100%;
        }
      }
    }

    .rating-icon {
      cursor: pointer;
      font-size: x-large;
      size: 50px;
      position: absolute;
      top:15px;
      right:15px;

      svg > path {
        stroke: #ffffff;
        stroke-width: 3;

        &:hover {
          stroke: #9ceeff;
        }
      }
    }
  }
}
