/*========== Colors ==========*/
$primary-color: rgb(93, 199, 252);
$text-color: hsl(0, 0%, 50%);
$secondary-color: #c8e3f6;
$body-color: hsl(0, 0%, 100%);
$title-color: gray;
$container-color: hsl(0, 0%, 93%);
$border-color: hsl(0, 0%, 87%);
$violet-color: hsl(276, 42%, 69%);
$black-color: hsl(0, 0%, 0%);

/*========== Font and Typography ==========*/
$body-font: Helvetica, Arial, sans-serif;
$second-font: "Poppins", sans-serif;
$h1-font-size: 50px;
$h2-font-size: 24px;
$h3-font-size: 21px;
$big-font-size: 56px;
$larger-font-size: 38px;
$large-font-size: 18px;
$normal-font-size: 16px;
$small-font-size: 15px;
$smaller-font-size: 14px;
$tiny-font-size: 12px;

/*========== Font Weight ==========*/
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;
$weight-800: 800;

/*========== Breakpoints ==========*/
$breakpoint-mobile: 480px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-large: 1280px;
