.star-rating {
  //nefunguje
  button {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: yellow;
    // color: ${(props) =>
    //   props.index <= ((props.rating && props.hover) || props.hover)
    //     ? "yellow"
    //     : "gray"};
  }
}
