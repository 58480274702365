@import "../../partials/variables";

.all-images {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 40px auto;

  .one-image {
    width: 350px;
    height: 320px;
    border: none;
    margin: 10px 15px;

    @media (max-width: 845px) {
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
    }

    article {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border: none;
      transition: all 0.9s ease-out;

      &:hover {
        transform: scale(1.04);
      }

      img {
        width: 100%;
        height: 80%;
        border-radius: 10px;
        transition: all 0.9s ease-out;

        &:hover {
          transform: scale(1.04);
        }
      }

      label {
        color: #000000;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
  
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .delete-image-btn {
    color: #fff;
    padding: 5px;
    cursor: pointer;
    width: 100%;
  }
}
