@import "../../partials/variables";

.container {

  h1 {
    margin-top: 20px;
  }
  
  .inner {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    justify-content: center;
    align-items: center;
    height: auto;

    @media (max-width: $breakpoint-desktop) {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    }

    img {
      width: 500px;
      height: 300px;

      @media (max-width: $breakpoint-mobile){
        width:250px;
        height: 150px;
        padding: 0rem 0.5rem
      }

    }

    .info {
      font-size: 1rem;
      margin-left: 100px;
      margin-top: 30px;

      

      @media (max-width: $breakpoint-mobile){
        margin-left: 60px;
        margin-right: 60px;
      }

      ul {
        margin: 20px 0;
      }

      li {
        margin: 10px 0;
      }
    }
  }
}