@import "../../partials/variables";

.service-tiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;

  a {
    text-decoration: none;
  }

  .one-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 300px;
    background-size: cover;
    background-repeat: no-repeat;
    filter: grayscale(50%);
    margin: 20px;

    &:hover {
      cursor: pointer;
    }
  }
}

.tile-icon {
  font-size: 7rem;
  color: $body-color;
}

.hideIcon-animation {
  display: none;
}

.showIcon-animation {
  animation: fade-in 0.5s ease-in-out;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.1) rotate(0.01deg);
  }
  100% {
    opacity: 1;
    transform: rotate(0.01deg);
  }
}

.fadeUp-animation {
  animation: 1.5s fadeUp;
}

@keyframes fadeUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.fadeDown-animation {
  animation: 1.5s fadeDown;
}

@keyframes fadeDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.on-blur-container {
  text-align: center;

  h2,
  h3 {
    color: $body-color;
    margin-bottom: 10px;
  }
  h3 {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }
}

.visibility {
  &--visible {
    transition: opacity 0.5s linear;
    opacity: 1;
  }
  &--hidden {
    transition: opacity 0.5s linear;
    opacity: 0;
  }
}
