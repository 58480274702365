@import "../../partials/variables";

.about {
  &-section {
    height: auto;
    margin: 5rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: 1.5;

    .about-background {
      display: flex;
      flex-direction: row;
      width: 95%;

      .background-logo {
        position: relative;
        padding-right: 150px;

        @media (max-width: $breakpoint-tablet) {
          padding-right: 0px;
        }

        img {
          position: absolute;
          top: 70px;
          left: 65px;
          width: 250px;
          height: 250px;

          // @media (max-width: 480px){
          //   width: 150px;
          //   height: 150px;
          // }
        }
      }

      .background-content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 40%;

        @media (max-width: 480px) {
          width: 90%;
        }
      }

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
      }
    }

    .second-part {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media (max-width: 1024px) {
        display: flex;
        flex-direction: column;
      }

      p {
        width:40%;
        margin-right: 20px;
        @media (max-width: 480px) {
          width: 90%;
          margin-right: 0;
        }
      }

      .circle-background {
        position: relative;
        width: 500px;
        height: 500px;
        padding-left: 150px;

        @media (max-width: 1024px) {
          padding-left: 0px;
        }

        @media (max-width: $breakpoint-tablet) {
          display: none;
        }

        // .circle {
        //   background-image: radial-gradient(
        //     circle at 3px 3px,
        //     rgb(70, 70, 70) 1px,
        //     transparent 0
        //   );
        //   background-size: 10px 10px;
        //   border-radius: 120px;
        //   box-shadow: inset -2px 0px 0px 15px rgb(255 255 255);
        //   height: 30vh;
        //   width: 30vh;
        //   margin: 0;
        // }

        //     .circle-image {
        //       background-image: url(../../images/Rekonstrukce_domu.jpg);
        //       border-radius: 150px;
        //       height: 40vh;
        //       width: 40vh;
        //       background-size: cover;
        //       position: absolute;
        //       top: 50px;
        //       left: 50px;

        //       @media (max-width: 768px) {
        //         height: 30vh;
        //         width: 30vh;
        //       }

        //       .layer {
        //         background-color: rgb(93, 199, 252, 0.7);
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         border-radius: 150px;
        //         font-size: xx-large;

        //         p {
        //           position: absolute;
        //           top: 80px;
        //           left: 100px;
        //           font-weight: 800;
        //           font-size: 50px;
        //         }

        //         span {
        //           position: absolute;
        //           top: 130px;
        //           left: 90px;
        //         }
        //       }
      }

      //     .blue-circle {
      //       background-color: rgb(93, 199, 252);
      //       border-radius: 150px;
      //       height: 30vh;
      //       width: 30vh;
      //       position: absolute;
      //       top: 350px;
      //       left: 150px;

      //       // @media (max-width: 1024px) {
      //       //   height: 25vh;
      //       //   width: 25vh;
      //       // }

      //       p {
      //         position: absolute;
      //         top: 50px;
      //         left: 90px;
      //         font-weight: 800;
      //         font-size: 50px;
      //       }

      //       span {
      //         position: absolute;
      //         top: 110px;
      //         left: 80px;
      //         font-size: xx-large;
      //       }
      //     }

      //     .blue-circle-medium {
      //       background-color: rgb(93, 199, 252);
      //       border-radius: 150px;
      //       height: 35vh;
      //       width: 35vh;
      //       position: absolute;
      //       top: 170px;
      //       left: 350px;

      //       p {
      //         position: absolute;
      //         top: 70px;
      //         left: 80px;
      //         font-weight: 800;
      //         font-size: 50px;
      //       }

      //       span {
      //         position: absolute;
      //         top: 140px;
      //         left: 80px;
      //         font-size: larger;
      //       }
      //     }

      //     .blue-circle-small-first {
      //       background-color: rgb(93, 199, 252, 0.5);
      //       border-radius: 150px;
      //       height: 5vh;
      //       width: 5vh;
      //       position: absolute;
      //       top: 100px;
      //       left: 370px;
      //     }

      //     .blue-circle-small-second {
      //       background-color: rgb(93, 199, 252, 0.5);
      //       border-radius: 150px;
      //       height: 5vh;
      //       width: 5vh;
      //       position: absolute;
      //       top: 380px;
      //       left: 90px;
      //     }

      //     .striped-circle-medium {
      //       border-radius: 120px;
      //       background-image: repeating-linear-gradient(
      //         45deg,
      //         #333,
      //         #333 3px,
      //         #fff 7px,
      //         #fff 9px
      //       );
      //       height: 25vh;
      //       width: 25vh;
      //       position: absolute;
      //       top: 220px;
      //       left: 220px;
      //       opacity: 0.7;
      //       z-index: -1;
      //     }

      //     .striped-circle {
      //       border-radius: 120px;
      //       background-image: repeating-linear-gradient(
      //         45deg,
      //         #333,
      //         #333 3px,
      //         #fff 7px,
      //         #fff 9px
      //       );
      //       height: 20vh;
      //       width: 20vh;
      //       position: absolute;
      //       top: 420px;
      //       left: 280px;
      //       opacity: 0.7;
      //       z-index: -1;
      //     }
      //   }

      // }

      // p {
      //   font-size: $large-font-size;
      // }

      // @media (max-width: 600px) {
      //   margin: 1rem;
      //   p {
      //     padding: 2rem;
      //   }

      //   @media (max-width: 380px) {
      //     margin: 1rem;
      //     p {
      //       padding: 1rem;
      //       font-size: 0.7rem;
      //     }
      //   }
    }

    .about-header {
      color: #333;
      text-align: center;
      font-size: $h1-font-size;
      padding: 2rem 0;

      @media (max-width: $breakpoint-desktop) {
        font-size: $h2-font-size;
        padding: 2rem 1rem;
      }
    }

    .about-paragraph {
      margin: 10px auto;
      text-align: center;
      p {
        width: 600px;
        margin: 10px auto;

        @media (max-width: 1024px) {
          width: 300px;
        }
      }
    }
  }
}
