.sign-in-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: url('../../images/background.jpg') no-repeat;
  background-size: cover;
  background-position: center;

  .sign-in-form{
    width: 420px;
    background: transparent;
    border: 2px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(30px);
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    color:#fff;
    border-radius: 10px;
    padding: 30px 40px;

    h3 {
      font-size: 36px;
      text-align: center;
      color:#fff;
    }

    .sign-in-input{
      position: relative;
      width: 100%;
      height: 50px;
      margin: 30px 0;

      input {
        width: 100%;
        height: 100%;
        background: transparent;
        outline: none;
        border-radius: 40px;
        font-size: 16px;
        color: #fff;
        padding: 20px 45px 20px 20px;

        &::placeholder {
          color:#fff;
        }
      }

      .icon {
        position:absolute;
        right: -20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    button {
      width: 100%;
    }
  }
}
