@import "../../partials/variables";

.contact {
  display: flex;
  flex-direction: column;

  section {
    display: flex;
    margin-bottom: 10rem;
    width: 100%;
    margin: 10rem auto;
    margin-bottom: 5rem;
    overflow: hidden;
    justify-content: space-evenly;

    h2 {
      margin-top: 40px;

      @media (max-width: 1280px) {
        display: flex;
        justify-content: center;
      }
    }

    @media (max-width: 1280px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 768px) {
      width: 70%;
    }

    @media (max-width: 380px) {
      h2 {
        font-size: 1rem;
      }
    }
  }
}

.section {
  display: flex;
  justify-content: flex-start;
  font-size: 0.9rem;
  padding: 1rem;
  width: 20rem;
  margin-bottom: 1rem;

  span {
    font-weight: bold;
    display: block;
  }

  @media (max-width: 1280px) {
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
}

.icon {
  color: $primary-color;
  font-size: 1.5rem;
  margin-right: 2rem;
}
