.all-slide-content {
  // height: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;

  button {
    background-color: transparent;
    font-size: 50px;
    color: gray;
    cursor: pointer;
    z-index: 10;
    padding: 0 0;

    &:hover {
      background: transparent;
    }
  }
}

.slide-content {
  // display: flex;
  // flex-direction: column;

  h3 {
    font-weight: normal;
    font-size: 2.5rem;
    padding: 2rem 1rem;
    transform: translateX(-100%);
  }

  .realization-link {
    display: flex;
    color: gray;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    position: relative;
    justify-content: flex-end;
    transform: translateX(100%);

    &::before {
      position: absolute;
      content: "";
      right: 0;
      bottom: 0;
      left: 0;
      width: 30%;
      height: 1px;
      transform: translateX(223%);

      background-color: #6a737b;
      transition: all 0.35s ease;
    }

    &:hover::before {
      bottom: -0.2rem;
      background-color: #8b9399;
      transition: all 0.35s ease;
    }
  }
}

.image-content {
  // display: flex;
  // flex-direction: row;
  width: 470px;
  // overflow: hidden;
  height: 400px;
  // align-items: center;
  position: relative;
  transition: all 0.9s ease-out;

  article {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // opacity: 0;
    padding: 0 1rem;

    img {
      width: 100%;
      height: 80%;
    }

    p {
      margin-top: 10px;
    }
  }

  article.active-slide {
    transform: translateX(-100%);
    opacity: 1;
  }

  article.next-slide {
    transform: translateX(0);
    opacity: 1;
  }

  article.second-next-slide {
    transform: translateX(100%);
    opacity: 1;
  }

  article.third-next-slide {
    transform: translateX(200%);
    display: none;
  }

  article.last-slide {
    transform: translateX(-200%);
    display: none;
  }

  // img {
  //   height: 200px;
  //   width: 300px;
  // }
}
